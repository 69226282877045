import React from "react"
import Checkbox from "@material-ui/core/Checkbox"
import { FormGroup, FormControlLabel, FormControl } from "@material-ui/core"
import CardFilter from "../CardFilter"

const FilterStyle = ({ setFilter, styles, filter }) => {
  const setFilterFalseOrTrue = (e, style) =>
    setFilter({
      ...filter,
      styles: { ...filter.styles, [style]: e.target.checked },
    })

  return (
    <CardFilter filters={filter.styles ? filter.styles : null} title={"Styles"}>
      <FormControl component="fieldset">
        <FormGroup>
          {styles.map(style => (
            <FormControlLabel
              key={style}
              control={
                <Checkbox
                  checked={filter.styles[style]}
                  onChange={e => setFilterFalseOrTrue(e, style)}
                  value={style}
                />
              }
              label={style}
            />
          ))}
        </FormGroup>
      </FormControl>
    </CardFilter>
  )
}
export default FilterStyle
