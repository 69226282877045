import React from "react"
import Checkbox from "@material-ui/core/Checkbox"
import { FormGroup, FormControlLabel, FormControl } from "@material-ui/core"
import CardFilter from "../CardFilter"

const FilterIsolation = ({ setFilter, datas, filter }) => {
  const setFilterFalseOrTrue = (e, datas) =>
    setFilter({
      ...filter,
      isolations: { ...filter.isolations, [datas]: e.target.checked },
    })

  return (
    <CardFilter
      filters={filter.isolations ? filter.isolations : null}
      title={"Isolations"}
    >
      <FormControl component="fieldset">
        <FormGroup>
          {datas.map(isolation => (
            <FormControlLabel
              key={isolation}
              control={
                <Checkbox
                  checked={filter.isolations[isolation]}
                  onChange={e => setFilterFalseOrTrue(e, isolation)}
                  value={isolation}
                />
              }
              label={isolation}
            />
          ))}
        </FormGroup>
      </FormControl>
    </CardFilter>
  )
}
export default FilterIsolation
