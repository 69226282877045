import React from "react"
import Checkbox from "@material-ui/core/Checkbox"
import { FormGroup, FormControlLabel, FormControl } from "@material-ui/core"
import CardFilter from "../CardFilter"

const FilterMateriaux = ({ setFilter, materiaux, filter }) => {
  const setFilterFalseOrTrue = (e, node) =>
    setFilter({
      ...filter,
      materiaux: { ...filter.materiaux, [node.name]: e.target.checked },
    })

  return (
    <CardFilter
      dark
      title={"Type de matériaux"}
      filters={filter.materiaux ? filter.materiaux : null}
    >
      <FormControl component="fieldset">
        <FormGroup>
          {materiaux.map(({ node }) => (
            <FormControlLabel
              key={node.name}
              control={
                <Checkbox
                  checked={filter.materiaux[node.name]}
                  onChange={e => setFilterFalseOrTrue(e, node)}
                  value={node.name}
                />
              }
              label={node.name}
            />
          ))}
        </FormGroup>
      </FormControl>
    </CardFilter>
  )
}
export default FilterMateriaux
