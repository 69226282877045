import React from "react"
import Checkbox from "@material-ui/core/Checkbox"
import { FormGroup, FormControlLabel, FormControl } from "@material-ui/core"
import CardFilter from "../CardFilter"

const FilterProduct = ({ setFilter, producTypes, filter }) => {
  const setFilterFalseOrTrue = (e, node) =>
    setFilter({
      ...filter,
      produit: { ...filter.produit, [node.name]: e.target.checked },
    })

  return (
    <CardFilter
      dark
      title={"Produits"}
      filters={filter.produit ? filter.produit : null}
    >
      <FormControl component="fieldset">
        <FormGroup>
          {producTypes.map(({ node }) => (
            <FormControlLabel
              key={node.name}
              control={
                <Checkbox
                  onChange={e => setFilterFalseOrTrue(e, node)}
                  value={node.name}
                  checked={filter.produit[node.name]}
                />
              }
              label={node.name}
            />
          ))}
        </FormGroup>
      </FormControl>
    </CardFilter>
  )
}
export default FilterProduct
