import React from 'react';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import ImageIcon from "@material-ui/icons/Image"
import BeachAccessIcon from "@material-ui/icons/BeachAccess"
import ProdIcon from "@material-ui/icons/Tune"
import MatIcon from "@material-ui/icons/Settings"

import styled from "styled-components"
import {Dialog, Button, DialogActions} from "@material-ui/core";

const FilterBottomNav = styled(BottomNavigation)`
  position:fixed;
  bottom:0;
  width: 100%;
`

const FilterBottom  = ({produits,isolation,materiaux,style}) => {
    const [value, setValue] = React.useState(0);
    const [open, setOpen] = React.useState(false);
const setOpenAndValue = (event, newValue) => {
    setValue(newValue);
    setOpen(true);
}
const handleClose = () => {
    setOpen(false)
}

    return (
        <>
        <FilterBottomNav
            value={value}
            onChange={setOpenAndValue}
            showLabels
        >
            <BottomNavigationAction label="Produits" icon={<ProdIcon />} />
            <BottomNavigationAction label="Isolation" icon={<BeachAccessIcon />} />
            <BottomNavigationAction label="Matériaux" icon={<MatIcon />} />
            <BottomNavigationAction label="Style" icon={<ImageIcon />} />
        </FilterBottomNav>
            <Dialog open={open} onClose={handleClose}>
                {value === 0 && produits}
                {value === 1 && isolation}
                {value === 2 && materiaux}
                {value === 3 && style}
                <DialogActions>
                    <Button onClick={handleClose} color="primary" autoFocus>
                        Valider
                    </Button>
                </DialogActions>
            </Dialog>
            </>
    );
}

export default FilterBottom