import React, { useState } from "react"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import PageTitle from "../components/PageTitle"
import {Grid, useMediaQuery,useTheme} from "@material-ui/core"
import RealisationCard from "../components/CardRealisation"
import { graphql } from "gatsby"
import FilterProduct from "../components/FilterProduct"
import FilterStyle from "../components/FilterStyle"
import FilterIsolation from "../components/FilterIsolation"
import FilterMateriaux from "../components/FilterMateriaux"
import FilterBottom from "../components/FilterBottom";

const TrouvezUneRealisation = ({ data }) => {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))
  const {
    allStrapiRealisation,
    allStrapiProduit,
    isolation,
    allStrapiMatiere,
  } = data

  let arrayMat = []
  allStrapiMatiere.edges.forEach(({ node }) => {
    arrayMat.push(node.name)
    return arrayMat
  })
    let arrayProd = []
    allStrapiProduit.edges.forEach(({ node }) => {
        arrayProd.push(node.name)
    return arrayProd
  })


  const matObj = arrayMat.reduce((o, key) => ({ ...o, [key]: true }), {})
  const prodObj = arrayProd.reduce((o, key) => ({ ...o, [key]: true }), {})
  const [filter, setFilter] = useState({
    produit: prodObj,
    materiaux: matObj,
    styles: { ancien: true, moderne: true },
    isolations: { autre: true, isole: true, nonisole: true },
  })
  const testMateriauxName = node =>
    node &&
    node.matieres &&
    node.matieres[0] &&
    node.matieres[0].name &&
    filter.materiaux[node.matieres[0].name]
  const testProduitsName = node =>
    node &&
    node.produits &&
    node.produits[0] &&
    node.produits[0].name &&
    filter.produit[node.produits[0].name]

  return (
    <Layout>
      <SEO title="Toutes nos réalisations, trouvez celle que vous recherchez" />
      <PageTitle />
      <Grid container spacing={2}>
        <Grid item sm={12} md={8} >
          <Grid container spacing={2}>
            {allStrapiRealisation.edges.map(({ node }) => {
              if (
                testProduitsName(node) &&
                testMateriauxName(node) &&
                filter.isolations[node.isolation] &&
                filter.styles[node.style]
              ) {
                return (
                  <Grid item sm={6} xs={12} key={node.id}>
                    <RealisationCard details={node} key={node.id} />
                  </Grid>
                )
              }
            })}
          </Grid>
        </Grid>
        {isDesktop &&
        <Grid item md={4}>
          <FilterProduct
            setFilter={setFilter}
            producTypes={allStrapiProduit.edges}
            filter={filter}
          />
          <br />
          <FilterIsolation
            setFilter={setFilter}
            datas={isolation.distinct}
            filter={filter}
          />
          <br />
          <FilterMateriaux
            setFilter={setFilter}
            materiaux={allStrapiMatiere.edges}
            filter={filter}
          />
          <br />
          <FilterStyle
            setFilter={setFilter}
            styles={allStrapiRealisation.distinct}
            filter={filter}
          />
        </Grid>
        }
      </Grid>
      {!isDesktop &&
      <FilterBottom produits={<FilterProduct
          setFilter={setFilter}
          producTypes={allStrapiProduit.edges}
          filter={filter}
      />} isolation={ <FilterIsolation
          setFilter={setFilter}
          datas={isolation.distinct}
          filter={filter}
      />} materiaux={<FilterMateriaux
          setFilter={setFilter}
          materiaux={allStrapiMatiere.edges}
          filter={filter}
      />} style={<FilterStyle
          setFilter={setFilter}
          styles={allStrapiRealisation.distinct}
          filter={filter}
      />}/>}
    </Layout>
  )
}

export default TrouvezUneRealisation

export const query = graphql`
  query allReal {
    allStrapiRealisation(
      sort: { fields: created_at, order: DESC }
      limit: 1000
    ) {
      edges {
        node {
          id
          name
          desc
          matieres {
            name
          }
          produits {
            name
          }
          isolation
          style
          ville {
            name
          }
          url
          photo {
            url
          }
          legacyimage {
            image
          }
        }
      }
      distinct(field: style)
    }
    allStrapiProduit {
      edges {
        node {
          name
        }
      }
    }
    allStrapiMatiere {
      edges {
        node {
          name
        }
      }
    }
    isolation: allStrapiRealisation(
      sort: { fields: created_at, order: DESC }
      limit: 1000
    ) {
      edges {
        node {
          id
        }
      }
      distinct(field: isolation)
    }
  }
`
