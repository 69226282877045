import React from "react"
import { Paper, Typography, Divider } from "@material-ui/core"
import styled from "styled-components"

interface Card {
  dark?: boolean
  children: React.ReactChild
  title: string
  filters?: []
}

const PaperMarge = styled(Paper)`
  padding: 15px;
  background-color: ${props => (props.dark ? "#36474f" : "#ffffff")};
  color: ${props => (props.dark ? "#ffffff" : "#000000")};
`

const DividerMarge = styled(Divider)`
  margin: 10px 0 10px 0;
`

const CardFilter = ({ children, dark, filters, title }: Card) => {
  let filterActives = Object.keys(filters).map(
    filter => filters[filter] && filter + " "
  )

  return (
    <PaperMarge dark={dark ? dark : null}>
      <Typography variant="h6" component="h3">
        {title}
      </Typography>
      {children}
      <DividerMarge />
      <Typography variant="overline" component="p">
        {filterActives.every(e => !e) ? "Aucun Filtre" : filterActives}
      </Typography>
    </PaperMarge>
  )
}

export default CardFilter
